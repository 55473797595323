/**
 * This file houses the color definitions for the Design System.
 * Definitions are based on the tokens and Figma variables defined in:
 * https://www.figma.com/file/LZWON29XVriIx2d8QD1whCO3/Colors-and-Type
 * (see the "Color - App") file for current values.
 *
 * The main purpose of the file is to allow us to take this set of colors
 * and make them available in Tailwind's tailwind.config.js and mixed-into
 * the legacy MaterialUI theme.
 *
 * However, it also serves a second purpose wherein if you absolutely needed
 * to, you could import this file in your JS and have access to the colors.
 * The use case for this is /extremely/ narrow, but a possibility would be
 * something like a color picker that the user can interact with for chart
 * colors. In almost all other scenarios, you should define your colors
 * via Tailwind or MUI - depending on the section of the app you're working on.
 */

const globalColors = Object.freeze({
  'black-000': '#000',
  'white-000': '#FFF',
  'grey-100': '#F6F7F9',
  'grey-200': '#E7EAEF',
  'grey-300': '#D0D5D7',
  'grey-400': '#9B9B9B',
  'grey-500': '#686B6C',
  'grey-600': '#494B4B',
  'grey-tint-100': '#3D50701A',
  'green-100': '#E5FEE1',
  'green-200': '#CCFDC3',
  'green-300': '#6CF054',
  'green-400': '#5CD746',
  'green-500': '#6AAF6F',
  'green-600': '#08493B',
  'sage-300': '#C8EFC7',
  'sage-500': '#E1EFE1',
  'sage-600': '#CDD8D4',
  'blue-500': '#4B71A9',
  'red-200': '#FADADC',
  'red-500': '#E11E29',
  'coral-100': '#FDF0F0',
  'coral-400': '#ED6864',
  'yellow-100': '#FFFBF1',
  'yellow-200': '#FFF1D7',
  'yellow-400': '#F6B901',
  'yellow-600': '#DAA401',
  picker: {
    'amber-200': '#FFE18D',
    'amber-300': '#F9D167',
    'amber-500': '#DCB34A',
    'amber-600': '#ECB32C',
    'blue-300': '#4F73F5',
    'blue-400': '#2B56ED',
    'emerald-400': '#59AA83',
    'fuchsia-400': '#B10080',
    'green-200': '#72B700',
    'green-400': '#116400',
    'indigo-600': '#000C72',
    'lime-100': '#C5D849',
    'lime-200': '#9AAF18',
    'orange-300': '#F49144',
    'orange-400': '#E07724',
    'orange-500': '#C6620A',
    'pink-300': '#E8548D',
    'pink-400': '#D63B79',
    'purple-400': '#A42ADD',
    'rose-200': '#FD9C90',
    'rose-600': '#680003',
    'sky-100': '#C0E5FE',
    'sky-200': '#9ACDF0',
    'sky-400': '#4D9CD5',
    'teal-200': '#65E7DB',
    'teal-400': '#32B8B2',
    'violet-100': '#ECC9FF',
    'violet-200': '#DC99FF',
    'violet-400': '#7B45F0',
    'yellow-300': '#A19200',
  },
});

const brandColors = Object.freeze({
  yellow: '#FFCF5D',
  coral: '#ED6864',
  sand: '#EAE7DB',
  orange: '#DE7C1D',
  slate: '#3C4746',
  lightGray: '#D8DADA',
});

const lightColors = Object.freeze({
  transparent: 'transparent',
  background: {
    backdrop: '#00000088',
    primary: globalColors['white-000'],
    1: globalColors['grey-100'],
    2: globalColors['grey-200'],
  },
  border: {
    brand: brandColors.yellow,
    default: globalColors['grey-300'],
    'calculations-total': globalColors['black-000'],
    muted: globalColors['grey-200'],
    separator: globalColors['grey-200'],
  },
  button: {
    primary: globalColors['black-000'],
    'primary-hover': globalColors['grey-600'],
    secondary: globalColors['white-000'],
    'secondary-hover': globalColors['grey-100'],
    'secondary-outline': globalColors['black-000'],
    'focus-outline': globalColors['green-300'],
    inactive: globalColors['grey-200'],
    'icon-bg': 'transparent',
    'icon-hover': globalColors['grey-tint-100'],
    'icon-outline': globalColors['grey-300'],
    destructive: globalColors['red-500'],
    'destructive-hover': globalColors['red-200'],
    warning: globalColors['yellow-400'],
    'warning-hover': globalColors['yellow-600'],
    'warning-focus-outline': brandColors.orange,
    'warning-text': globalColors['black-000'],
  },
  checkbox: {
    disabled: globalColors['grey-300'],
    'disabled-bg': globalColors['grey-200'],
    focused: globalColors['green-400'],
    selected: globalColors['black-000'],
    unselected: globalColors['grey-400'],
  },
  'item-status': {
    rejected: globalColors['red-500'],
    'rejected-tint': globalColors['red-200'],
    pending: globalColors['yellow-400'],
    'pending-tint': globalColors['yellow-200'],
    accepted: globalColors['green-500'],
    'accepted-tint': globalColors['sage-500'],
    incorporated: globalColors['green-600'],
    'incorporated-tint': globalColors['sage-600'],
    'not-applicable': globalColors['grey-400'],
    'not-applicable-tint': globalColors['grey-200'],
    'not-chosen': globalColors['grey-400'],
    'not-chosen-tint': globalColors['grey-200'],
  },
  badge: {
    notification: globalColors['coral-400'],
    default: globalColors['grey-500'],
  },
  radio: {
    disabled: globalColors['grey-300'],
    'disabled-bg': globalColors['grey-200'],
    focused: globalColors['green-400'],
    selected: globalColors['black-000'],
    unselected: globalColors['grey-400'],
  },
  scrollbar: {
    fg: globalColors['grey-400'],
  },
  switch: {
    'disabled-off': globalColors['grey-200'],
    'disabled-on': globalColors['grey-400'],
    hover: globalColors['green-100'],
    'knob-active': globalColors['green-400'],
    'knob-off': globalColors['white-000'],
    'track-active': globalColors['green-200'],
    'track-off': globalColors['grey-300'],
  },
  selection: {
    hover: globalColors['green-100'],
    selected: globalColors['green-200'],
    'focus-outline': globalColors['green-300'],
    'focus-fill': globalColors['green-400'],
  },
  'status-banner': {
    'default-bg': globalColors['grey-100'],
    'error-bg': globalColors['coral-100'],
    'success-bg': globalColors['green-100'],
    'error-icon': globalColors['coral-400'],
    'warning-icon': globalColors['yellow-400'],
    'info-icon': globalColors['grey-400'],
    'success-icon': globalColors['green-500'],
  },
  'marketing-banner': {
    'default-bg': globalColors['yellow-100'],
  },
  chart: {
    axis: globalColors['grey-300'],
    'axis-label': globalColors['grey-400'],
    'axis-ticks': globalColors['grey-100'],
    'base-line': globalColors['black-000'],
    'pending-cost-area': '#f6b90199',
  },
  entities: {
    allowances: '#2684C8',
    'allowances-drawn': '#C0E5FE',
    'allowances-label': '#237CBC',
    'allowances-future': '#DFEFF4',
    'comparison-better': globalColors['sage-500'],
    'comparison-worse': globalColors['coral-100'],
    contingencies: '#25469D',
    'contingencies-drawn': '#92B0FF',
    'contingencies-label': '#25469D',
    'contingencies-future': '#DFE4F1',
    event: globalColors['coral-400'],
    'item-decided': globalColors['grey-300'],
    'item-pastdue': globalColors['coral-400'],
    'item-upcoming': globalColors['yellow-400'],
    'item-missing': globalColors['yellow-200'],
    gap: globalColors['grey-400'],
    'baseline-gmp': '#E07724',
    'baseline-gmp-bg': '#FFE18D',
    'baseline-active-milestone': globalColors['white-000'],
    'baseline-active-milestone-bg': globalColors['blue-500'],
    'baseline-yellow-bg': '#FFFBF1',
    markups: '#157E84',
    milestone: globalColors['blue-500'],
    estimate: globalColors['black-000'],
    'estimate-future': '#E2E2E2',
    budget: globalColors['blue-500'],
    'budget-future': '#ECF0F6',
    overdrawn: globalColors['red-500'],
    'owner-cost': '#62AEB7',
    phase: globalColors['grey-300'],
    'phase-future': globalColors['grey-100'],
    'status-pending-future': '#FEF9E8',
    today: globalColors['green-400'],
  },
  type: {
    primary: globalColors['black-000'],
    secondary: globalColors['white-000'],
    link: globalColors['blue-500'],
    inactive: globalColors['grey-400'],
    error: globalColors['red-500'],
    delete: globalColors['red-500'],
    muted: globalColors['grey-400'],
    success: globalColors['green-500'],
    warning: globalColors['yellow-400'],
  },
});

const darkColors = Object.freeze({
  ...lightColors,
  background: {
    backdrop: '#00000080',
    primary: '#3C4746',
    1: '#565D59',
    2: '#333836',
  },
  border: {
    ...lightColors.border,
    default: '#8E938D',
    'calculations-total': '#646C68',
    muted: '#646C68',
    separator: '#646C68',
  },
  button: {
    ...lightColors.button,
    primary: '#646C68',
    'primary-hover': '#8E938D',
    secondary: '#3C4746',
    'secondary-hover': '#646C68',
    'secondary-outline': '#8E938D',
    'focus-outline': '#5A9851',
    'icon-bg': 'transparent',
    'icon-hover': '#EBF4EB3D',
    inactive: '#333836',
    'icon-outline': '#8E938D',
    destructive: '#F29CA1',
    'destructive-hover': '#2D0608',
  },
  checkbox: {
    disabled: '#646C68',
    'disabled-bg': '#333836',
    focused: '#5A9851',
    selected: brandColors.yellow,
    unselected: brandColors.sand,
  },
  entities: {
    ...lightColors.entities,
    milestone: '#B1C3DD',
    gap: '#C5BC9B',
  },
  'item-status': {
    rejected: '#F29CA1',
    'rejected-tint': '#FFF', // placeholder
    pending: '#FEE290',
    'pending-tint': '#FFF', // placeholder
    accepted: '#8BC18F',
    'accepted-tint': '#FFF', // placeholder
    incorporated: '#8BBBB0',
    'incorporated-tint': '#FFF', // placeholder
    'not-applicable': '#C5BC9B',
    'not-applicable-tint': '#FFF', // placeholder
    'not-chosen': '#C5BC9B',
    'not-chosen-tint': '#FFF', // placeholder
  },
  radio: {
    disabled: '#646C68',
    'disabled-bg': '#333836',
    focused: '#5A9851',
    selected: brandColors.yellow,
    unselected: brandColors.sand,
  },
  scrollbar: {
    fg: '#BBBCB3',
  },
  selection: {
    ...lightColors.selection,
    hover: '#646C68',
    selected: '#565D59',
    'focus-outline': '#5A9851',
  },
  switch: {
    'disabled-off': '#8E938D',
    'disabled-on': '#9B9B9B',
    hover: '#E0C58B',
    'knob-active': '#FFCF5D',
    'knob-off': '#BBBCB3',
    'track-active': '#C9B078',
    'track-off': '#8E938D',
  },
  type: {
    primary: brandColors.sand,
    secondary: brandColors.sand,
    link: brandColors.yellow,
    inactive: '#8E938D',
    error: '#F29CA1',
    delete: '#F29CA1',
    muted: '#BBBCB3',
    success: '#8BC18F',
    warning: '#FEE290',
  },
});

const colors = {
  light: lightColors,
  dark: darkColors,
} as const;

const pickerColors = [
  globalColors.picker['amber-200'],
  globalColors.picker['pink-400'],
  globalColors.picker['green-400'],
  globalColors.picker['rose-600'],
  globalColors.picker['sky-100'],
  globalColors.picker['orange-400'],
  globalColors.picker['blue-300'],
  globalColors.picker['lime-200'],
  globalColors.picker['amber-600'],
  globalColors.picker['purple-400'],
];
// These are the colors to use in charts when there is no color significance.
const colorWheel = [
  globalColors.picker['indigo-600'],
  globalColors.picker['blue-400'],
  globalColors.picker['sky-400'],
  globalColors.picker['sky-200'],
  globalColors.picker['teal-400'],
  globalColors.picker['teal-200'],
  globalColors.picker['emerald-400'],
  globalColors.picker['green-200'],
  globalColors.picker['lime-100'],
  globalColors.picker['yellow-300'],
  globalColors.picker['amber-500'],
  globalColors.picker['amber-300'],
  globalColors.picker['orange-300'],
  globalColors.picker['orange-500'],
  globalColors.picker['rose-200'],
  globalColors.picker['pink-300'],
  globalColors.picker['fuchsia-400'],
  globalColors.picker['violet-100'],
  globalColors.picker['violet-200'],
  globalColors.picker['violet-400'],
];

export { pickerColors, colorWheel };

export default colors;

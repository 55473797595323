import mime from 'mime';

import { getOperationName } from '@apollo/client/utilities';
import { Auth0ContextInterface } from '@auth0/auth0-react';

import { exportEstimateAnalytics } from '../analytics/analyticsEventProperties';
import { isDefaultReport } from '../components/ReportsTab/ReportsManagerMenu/utils';
import {
  EstimateType,
  ProjectCompsSetInput,
  Status,
  UserReportCommentViewParameters,
} from '../generated/graphql';
import { AnalyticsEvent } from '../hooks/useSendAnalytics';
import { tokenUtil } from '../utilities/authUtils';
import {
  getDownloadedMessage,
  getDownloadingMessage,
  makeFilename,
  showExportToast,
} from '../utilities/export';
import { toastServerError } from '../utilities/toast';
import { getMilestoneIdFromUrl, getProjectIdFromUrl } from '../utilities/url';

import {
  currentUserReportVar,
  isDownloadingBudgetToExcelVar,
  isDownloadingContingencyReportToExcelVar,
  isDownloadingCostReportToExcelVar,
  isDownloadingEstimateToExcelVar,
  isDownloadingItemsListToExcelVar,
  isDownloadingProjectCompsToExcelVar,
  isDownloadingProjectCostBreakdownReportToExcelVar,
} from './apollo/reactiveVars';
import { ExportType } from './gqlEnums';
import { GraphQLDocument } from './graphqlFragments';

let apiUrl = 'http://localhost:3001';
if (process.env.REACT_APP_MOCK_GRAPHQL) {
  apiUrl = 'http://localhost:4000';
}
if (window.location.hostname.substring(0, 20) === 'host.docker.internal') {
  apiUrl = 'http://host.docker.internal:3001';
}
export const isProduction = window.location.hostname.substring(0, 14) === 'app.join.build';
if (isProduction) {
  apiUrl = 'https://api.join.build';
}
if (window.location.hostname.substring(0, 18) === 'staging.join.build') {
  apiUrl = 'https://staging-api.join.build';
}

if (window.location.hostname.substring(0, 25) === 'master-staging.join.build') {
  apiUrl = 'https://master-staging-api.join.build';
}

if (window.location.hostname.substring(0, 26) === 'app.new-staging.join.build') {
  apiUrl = 'https://api.new-staging.join.build';
}

if (window.location.hostname.substring(0, 33) === 'app.new-master-staging.join.build') {
  apiUrl = 'https://api.new-master-staging.join.build';
}

if (window.location.hostname.substring(0, 21) === 'test-infra.join.build') {
  apiUrl = 'https://test-infra-api.join.build';
}

if (window.location.hostname.substring(0, 18) === 'app.dev.join.build') {
  apiUrl = 'https://api.dev.join.build';
}
if (window.location.hostname.startsWith('feature-testing-')) {
  // Todo: This will break on parsing with double-digit urls
  const number = window.location.hostname.substring(16, 17);
  apiUrl = `https://feature-testing-${number}-api.join.build:3001`;
}
// if (window.location.hostname.endsWith('dev.join.build')) {
//   apiUrl = `https://api.${window.location.hostname.substring(3)}`;
// }
if (process.env.REACT_APP_SERVER_PORT) {
  apiUrl = `http://localhost:${process.env.REACT_APP_SERVER_PORT}`;
}

const featRegex = /app.feat-[a-z0-9]*.join.build/;
const hostname = window.location.hostname;
if (featRegex.test(hostname)) {
  const url = hostname.replace('app', 'api');
  apiUrl = `https://${url}`;
}

const assetStoreUrl = apiUrl;

export function apiRoute(path: string) {
  if (path[0] === '/') {
    return apiUrl + path;
  }
  return `${apiUrl}/${path}`;
}

function errRequestFailed(xhr: XMLHttpRequest) {
  return new Error(`Request failed: ${xhr.status} ${xhr.statusText} ${xhr.response}`);
}
function xhrOnErrorHandler(xhr: XMLHttpRequest) {
  return new Error(`XHR error encounterd: ${xhr.status} ${xhr.statusText} ${xhr.response}`);
}

function parseResponseHeaders(xhr: XMLHttpRequest) {
  const headersString = xhr.getAllResponseHeaders();
  const headersArr = headersString.trim().split(/[\r\n]+/);
  // Create a map of header names to values
  const headers: Record<string, string> = {};
  for (let i = 0, len = headersArr.length; i < len; i += 1) {
    const parts = headersArr[i].split(': ');
    const header = parts.shift();
    const value = parts.join(': ');
    if (header) headers[header] = value;
  }
  return headers;
}

// options = {
//     method: 'GET' | 'POST' | ...
//     headers: {
//         'Header1': 'asdf',
//         ...
//     },
//     body: Blob | string | FormData | ... (anything allowable as XMLHttpRequest data),
// }

type RequestOptions = {
  method?: string;
  body?: Blob | string | FormData | ArrayBuffer | null;
  headers?: Record<string, string>;
  responseType?: XMLHttpRequestResponseType;
};

async function getAccessToken() {
  return localStorage.getItem('skip_signup_flow')
    ? localStorage.getItem('access_token')
    : tokenUtil.getAccessTokenSilently();
}

const DEFAULT_REQUEST_OPTIONS: Required<RequestOptions> = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/graphql',
  },
  body: null,
  responseType: 'text',
};
async function request(
  path: string,
  options: RequestOptions = DEFAULT_REQUEST_OPTIONS,
  auth?: Auth0ContextInterface
): Promise<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  body: any;
  status: number;
  statusText: string;
  headers: Record<string, string>;
}> {
  // Checks for local access token first in an integration testing environment then through the interface methods
  const accessToken = await getAccessToken();

  return new Promise((resolve, reject) => {
    // fall back to the default request options if some options are undefined
    const { method, headers, body, responseType } = { ...DEFAULT_REQUEST_OPTIONS, ...options };
    const xhr = new XMLHttpRequest();
    if (responseType) xhr.responseType = responseType;
    xhr.onload = () => {
      if (xhr.status < 400 && xhr.status > 0) {
        resolve({
          body: xhr.response,
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseResponseHeaders(xhr),
        });
      } else if (
        (!process.env.REACT_APP_MOCK_GRAPHQL && xhr.status === 401) ||
        (auth && !auth?.isAuthenticated) // auth is rarely passed to this function
      ) {
        // Short circuit if we're unauthorized and we're not authenticated.
        auth?.logout({ returnTo: window.location.origin });
      } else {
        toastServerError();
        reject(errRequestFailed(xhr));
      }
    };
    xhr.onerror = () => {
      toastServerError();
      reject(xhrOnErrorHandler(xhr));
    };
    xhr.open(method, apiRoute(path));
    xhr.withCredentials = true;

    let contentTypeSet = false;
    for (let i = 0, keys = Object.keys(headers), len = keys.length; i < len; i += 1) {
      if (keys[i] === 'Authorization') {
        throw new Error("Don't override the Authorization header.");
      }
      xhr.setRequestHeader(keys[i], headers[keys[i]]);
      if (keys[i] === 'Content-Type') {
        contentTypeSet = true;
      }
    }

    if (accessToken) {
      xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    }
    if (body) {
      if (!contentTypeSet) {
        xhr.setRequestHeader('Content-Type', 'application/json');
      }
      xhr.send(body);
    } else {
      xhr.send();
    }
  });
}

function requestAssetBlobURL(location: string, file: string, auth?: Auth0ContextInterface) {
  const typeObject = { type: mime.getType(file) || undefined };
  return request(
    location,
    {
      responseType: 'blob',
    },
    auth
  ).then((resp) => URL.createObjectURL(new Blob([resp.body], typeObject)));
}

/** @deprecated use a standard useQuery/useMutation, or use apolloClient.query()
 * This is only being used in imperative JoinGrid code for now, which is being
 * phased out.
 */
function requestGraphQL(queryObj: GraphQLDocument, variables: Record<string, unknown>) {
  const query = typeof queryObj !== 'string' ? queryObj.cachedQuery : queryObj;
  const body = JSON.stringify({ operationName: getOperationName(queryObj), query, variables });
  const pathname = window.location.pathname;

  return request('graphql', {
    method: 'POST',
    headers: {
      // These are the same headers as the ones set using the ApolloLink in RootRoute.tsx.
      'Join-Uri': pathname,
      'Join-ProjectId': getProjectIdFromUrl(pathname),
      'Join-MilestoneId': getMilestoneIdFromUrl(pathname),
    },
    body,
  }).then((resp) => JSON.parse(resp.body));
}

function requestLogin(auth: Auth0ContextInterface) {
  return request('login', { method: 'POST', body: JSON.stringify(auth.user) }, auth);
}

function readFile(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.readAsArrayBuffer(file);
  });
}

function encodeBase64(buf: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buf);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

// eslint-disable-next-line no-bitwise
const mebibyte = 1 << 20;

const partSize = 5 * mebibyte; // send 5MiB per part

async function upload(body: string, file: File) {
  const contents = await readFile(file);
  const resp = await request('beginUpload', {
    method: 'POST',
    body,
  });
  const sessionKey = resp.headers['x-join-upload-session'];
  if (!sessionKey) {
    throw new Error(
      `Failed to get session key from beginUpload response header: ${JSON.stringify(resp.headers)}`
    );
  }
  const requests = [];
  const numParts = Math.ceil(file.size / partSize);
  for (let i = 1; i <= numParts; i += 1) {
    const partBody = contents.slice((i - 1) * partSize, i * partSize);
    const partHeaders: Record<string, string> = {
      'X-Join-Upload-Session': sessionKey,
      'X-Join-Part-Number': String(i),
    };
    const p = crypto.subtle
      .digest('SHA-256', partBody)
      .then(encodeBase64)
      .then((digest) => {
        partHeaders.Digest = `SHA-256=${digest}`;
        return request('upload', {
          method: 'POST',
          body: partBody,
          headers: partHeaders,
        });
      });
    requests.push(p);
  }
  await Promise.all(requests);
  const finishBody = {
    numParts,
  };
  const response = await request('finishUpload', {
    method: 'POST',
    body: JSON.stringify(finishBody),
    headers: {
      'X-Join-Upload-Session': sessionKey,
    },
  });
  return JSON.parse(response.body);
}

function importCategories(file: File, categorizationID?: UUID, projectID?: UUID) {
  const body = JSON.stringify({
    filename: file.name,
    size: file.size,
    lastUpdated: new Date(file.lastModified).toISOString(),
    categorizationID,
    projectID,
  });
  return upload(body, file);
}

function importEstimate(
  projectID: UUID,
  file: File,
  milestoneID: UUID,
  estimateType: EstimateType
) {
  const body = JSON.stringify({
    filename: file.name,
    size: file.size,
    lastUpdated: new Date(file.lastModified).toISOString(),
    projectID,
    milestoneID,
    estimateType,
  });
  return upload(body, file);
}

const importItems = (file: File, projectID?: UUID) => {
  const body = JSON.stringify({
    filename: file.name,
    size: file.size,
    lastUpdated: new Date(file.lastModified).toISOString(),
    projectID,
    type: 'items',
  });
  return upload(body, file);
};

function uploadAsset(projectID: UUID | null, file: File) {
  const body = JSON.stringify({
    filename: file.name,
    size: file.size,
    lastUpdated: new Date().toISOString(),
    projectID,
  });
  return upload(body, file);
}

async function makeDownloadURL(asset: Pick<Asset, 'id' | 'location'>) {
  const accessToken = await getAccessToken();

  if (!asset.location) {
    throw new Error(`Asset doesn't have a location set: ${JSON.stringify(asset)}`);
  }
  if (asset.location[0] === '/') {
    return `${assetStoreUrl}${asset.location}?auth=${accessToken}`;
  }
  return `${assetStoreUrl}/${asset.location}?auth=${accessToken}`;
}

function makeDerivativeDownloadURL(derivativeId: string, derivativeContent: string) {
  if (derivativeContent.includes('http')) return derivativeContent; // TODO: Remove. handles dummy data for now
  return `${assetStoreUrl}/derivations/${derivativeId}/${derivativeContent}`;
}

function downloadExcel(s: BufferSource | Blob | string, fileNameTokens: string[]) {
  const blob = new Blob([s], {
    type: 'application/octet-stream',
  });
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  const filename = makeFilename(fileNameTokens);
  downloadLink.download = filename;
  downloadLink.click();
}

// we need the time zone to convert UTC to local time for any exported dates
// go uses adjusts to a location using the seconds offset from UTC
// whereas javascript gives us the minutes subtracted from UTC
// we need to mulitply by -60 for go's conversion to work
export function getTimezoneOffset() {
  return new Date().getTimezoneOffset() * -60;
}

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

function exportCategories(
  categorizationID?: string,
  categorizationTemplate?: string,
  fileNameTokens?: string[]
) {
  request(`export/categories`, {
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      categorizationID,
      categorizationTemplate,
    }),
  }).then((s) => {
    downloadExcel(s.body, fileNameTokens || ['Categories']);
  });
}

function importItemsTemplate(projectID: UUID, projectName: string) {
  request(`export/itemsTemplate`, {
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({ projectID }),
  }).then((s) => {
    downloadExcel(s.body, ['Join Items Template', projectName]);
  });
}

function exportItemsList(
  itemIDs: UUID[],
  projectID: UUID,
  milestoneID: UUID | null,
  viewFilter: ViewFilterInput,
  viewFilterText: string[],
  includeMarkups: boolean,
  fileNameTokens: string[],
  onFinishDownload: () => void = () => undefined
) {
  const timezoneOffset = getTimezoneOffset();
  if (isDownloadingItemsListToExcelVar()) return;
  isDownloadingItemsListToExcelVar(true);
  showExportToast(getDownloadingMessage(fileNameTokens));
  request(`export/items`, {
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      itemIDs,
      projectID,
      milestoneID,
      viewFilter,
      viewFilterText,
      includeMarkups,
      timezoneOffset,
    }),
  })
    .then((s) => {
      downloadExcel(s.body, fileNameTokens);
      showExportToast(getDownloadedMessage(fileNameTokens));
      onFinishDownload();
    })
    .finally(() => isDownloadingItemsListToExcelVar(false));
}

function downloadZip(s: string) {
  const blob = new Blob([s], {
    type: 'application/zip',
  });
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.click();
}

const updateRectiveVar = (type: ExportType, bool: boolean) => {
  if (type === ExportType.ESTIMATE) isDownloadingEstimateToExcelVar(bool);
  if (type === ExportType.BUDGET) isDownloadingBudgetToExcelVar(bool);
};

const isDownloading = (type: string) =>
  (type === ExportType.ESTIMATE && isDownloadingEstimateToExcelVar()) ||
  (type === ExportType.BUDGET && isDownloadingBudgetToExcelVar());

function exportEstimates(
  estimateIDs: UUID[],
  type: ExportType,
  includeMarkups: boolean,
  fileNameTokens: string[],
  sendAnalytics: (e: AnalyticsEvent) => void,
  onFinishDownload: () => void = () => undefined
) {
  if (!estimateIDs || !estimateIDs.length) {
    return;
  }
  const timezoneOffset = getTimezoneOffset();
  if (isDownloading(type)) return;
  updateRectiveVar(type, true);
  showExportToast(getDownloadingMessage(fileNameTokens));
  request(`export/estimates`, {
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      estimateIDs,
      timezoneOffset,
      includeMarkups,
    }),
  }).then((s) => {
    sendAnalytics(exportEstimateAnalytics(s));
    if (estimateIDs.length === 1) {
      downloadExcel(s.body, fileNameTokens);
      onFinishDownload();
    } else {
      downloadZip(s.body);
      onFinishDownload();
    }
    updateRectiveVar(type, false);
    showExportToast(getDownloadedMessage(fileNameTokens));
  });
}

function exportCostReport(
  projectID: UUID,
  groupBys: DisplayGroupBy[] | GroupByItem[],
  columnSets: VarianceColumnSet[],
  viewFilter: ViewFilterInput,
  costMode: CostMode,
  includeMarkups: boolean,
  isVarianceReport: boolean,
  fileNameTokens: string[],
  viewParameters: UserReportCommentViewParameters | undefined,
  includeDetailedItemCosts = true,
  onFinishDownload: () => void = () => undefined
) {
  const groupByItems: GroupByItem[] = groupBys.map((g) => ({
    ...g,
    categorization: {
      id: g.categorization.id,
      name: g.categorization.name,
    },
  }));
  const timezoneOffset = getTimezoneOffset();
  if (isDownloadingCostReportToExcelVar()) return;
  isDownloadingCostReportToExcelVar(true);
  const currentReport = currentUserReportVar();
  const reportID =
    currentReport && !isDefaultReport(currentReport.id) ? currentReport.id : undefined;
  showExportToast(getDownloadingMessage(fileNameTokens));
  request(`export/costReport`, {
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      projectID,
      groupByItems,
      columnSets,
      viewFilter,
      costMode,
      includeMarkups,
      timezoneOffset,
      isVarianceReport,
      viewParameters,
      includeDetailedItemCosts,
      reportID,
    }),
  })
    .then((s) => {
      downloadExcel(s.body, fileNameTokens);
      showExportToast(getDownloadedMessage(fileNameTokens));
      onFinishDownload();
    })
    .finally(() => isDownloadingCostReportToExcelVar(false));
}

type ExportProjectCompsArgs = {
  reportName: string;
  projectCompsSetInput?: ProjectCompsSetInput;
  reportID?: UUID;
};

function exportProjectComps(params: ExportProjectCompsArgs) {
  const fileNameTokens = ['Project Comparison Report', params.reportName];
  const timezoneOffset = getTimezoneOffset();
  if (isDownloadingProjectCompsToExcelVar()) return;
  isDownloadingProjectCompsToExcelVar(true);
  showExportToast(getDownloadingMessage(fileNameTokens));
  request(`export/projectComps`, {
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      projectCompsSetInput: params.projectCompsSetInput,
      // TODO - Fix DD-652 - send costTableColumnInputs with separate input to avoid json tag issue
      costTableColumnInputs: params.projectCompsSetInput?.costTableColumnInputs,
      reportID: params.reportID,
      timezoneOffset,
    }),
  })
    .then((s) => {
      downloadExcel(s.body, fileNameTokens);
      showExportToast(getDownloadedMessage(fileNameTokens));
    })
    .finally(() => isDownloadingProjectCompsToExcelVar(false));
}

function exportAllMilestonesContingencyReport(
  projectID: UUID,
  filteredContingencies: string[],
  filteredAllowances: string[],
  fileNameTokens: string[],
  reportID?: UUID,
  onFinishDownload: () => void = () => undefined
) {
  if (isDownloadingContingencyReportToExcelVar()) return;

  const timezoneOffset = getTimezoneOffset();
  isDownloadingContingencyReportToExcelVar(true);
  showExportToast(getDownloadingMessage(fileNameTokens));
  request(`export/allMilestonesContingencyReport`, {
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      projectID,
      filteredContingencies,
      filteredAllowances,
      timezoneOffset,
      reportID,
    }),
  })
    .then((s) => {
      downloadExcel(s.body, fileNameTokens);

      showExportToast(getDownloadedMessage(fileNameTokens));
      onFinishDownload();
    })
    .finally(() => isDownloadingContingencyReportToExcelVar(false));
}

function exportActiveMilestoneContingencyReport(
  projectID: UUID,
  milestoneID: UUID,
  itemStatuses: Status[],
  filteredContingencies: string[],
  filteredAllowances: string[],
  fileNameTokens: string[],
  reportID?: UUID,
  onFinishDownload: () => void = () => undefined
) {
  if (isDownloadingContingencyReportToExcelVar()) return;

  const timezoneOffset = getTimezoneOffset();
  isDownloadingContingencyReportToExcelVar(true);
  showExportToast(getDownloadingMessage(fileNameTokens));
  request(`export/activeMilestoneContingencyReport`, {
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      projectID,
      milestoneID,
      reportID,
      itemStatuses,
      filteredContingencies,
      filteredAllowances,
      timezoneOffset,
    }),
  })
    .then((s) => {
      downloadExcel(s.body, fileNameTokens);
      showExportToast(getDownloadedMessage(fileNameTokens));
      onFinishDownload();
    })
    .finally(() => isDownloadingContingencyReportToExcelVar(false));
}

function exportProjectCostBreakdownReport(
  projectID: UUID,
  milestoneID: UUID,
  estimateType: EstimateType,
  costMode: CostMode,
  fileNameTokens: string[],
  onFinishDownload: () => void = () => undefined
) {
  if (isDownloadingProjectCostBreakdownReportToExcelVar()) return;

  const timezoneOffset = getTimezoneOffset();
  isDownloadingProjectCostBreakdownReportToExcelVar(true);
  showExportToast(getDownloadingMessage(fileNameTokens));
  request(`export/projectCostBreakdownReport`, {
    responseType: 'blob',
    method: 'POST',
    body: JSON.stringify({
      projectID,
      milestoneID,
      estimateType,
      costMode,
      timezoneOffset,
    }),
  })
    .then((s) => {
      downloadExcel(s.body, fileNameTokens);

      showExportToast(getDownloadedMessage(fileNameTokens));
      onFinishDownload();
    })
    .finally(() => isDownloadingProjectCostBreakdownReportToExcelVar(false));
}

export default {
  apiUrl,
  exportItemsList,
  exportEstimates,
  exportCategories,
  exportCostReport,
  exportProjectComps,
  exportAllMilestonesContingencyReport,
  exportActiveMilestoneContingencyReport,
  exportProjectCostBreakdownReport,
  requestAssetBlobURL,
  requestGraphQL,
  requestLogin,
  importCategories,
  importEstimate,
  importItems,
  importItemsTemplate,
  uploadAsset,
  makeDownloadURL,
  makeDerivativeDownloadURL,
};

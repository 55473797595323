import { ComponentProps, Key, KeyboardEvent, useRef } from 'react';
import { AriaListBoxOptions, useHover, useListBox, useListBoxSection, useOption } from 'react-aria';
import { ListState, Node } from 'react-stately';

import { isMac } from '../../../utilities/utilities';
import Checkbox from '../Checkbox/Checkbox';
import Chip from '../Chip/Chip';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';

import { SelectEntry, SelectEntryID } from './types';

export default function EntryList(props: {
  isAllSelectable?: boolean;
  isMultiSelect?: boolean;
  menuProps: AriaListBoxOptions<SelectEntry>;
  onFilterEntry?: (entry: SelectEntry) => boolean | SelectEntry[];
  onHoverEntry?: (entryID: UUID) => void;
  onViewChildren?: (parentID: SelectEntryID) => void;
  onViewParent: () => void;
  parentEntryID?: SelectEntryID | null;
  state: ListState<SelectEntry>;
}) {
  const { state } = props;

  const ref = useRef<HTMLUListElement>(null);
  const { listBoxProps } = useListBox(
    {
      ...props.menuProps,
      selectionMode: props.isMultiSelect ? 'multiple' : 'single',
    },
    props.state,
    ref
  );

  const { onKeyDown } = listBoxProps;
  listBoxProps.onKeyDown = (e) => {
    // By default, Esc will clear all selected entries in a MultiSelect context. That's silly.
    if (e.key === 'Escape') return;

    // By default, the Ctrl/Cmd+A action only selects all the entries, it won't deselect them.
    if (
      props.isAllSelectable &&
      ((isMac() && e.metaKey && e.key === 'a') || (!isMac() && e.ctrlKey && e.key === 'a'))
    ) {
      state.selectionManager.toggleSelectAll();
    }

    // Handle nav for arrow keys to support filtering / children.
    if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
      handleKeyboardNavEvent(e, ref.current, state.selectionManager.focusedKey);
    } else {
      onKeyDown?.(e);
    }
  };

  const parentEntry = props.parentEntryID
    ? state.collection.getItem(props.parentEntryID)
    : undefined;

  const entries = [...state.collection].flatMap((e) => {
    if (!e.value) return [];
    if (!props.onFilterEntry) return [e];

    const res = props.onFilterEntry(e.value);
    if (typeof res === 'boolean') return res ? [e] : [];

    return res.flatMap((e) => {
      const node = props.state.collection.getItem(e.id);
      return node ? [node] : [];
    });
  });

  /*
   * Select all selects the entries in the current view as well as their parent, if exists.
   * If select all is unchecked or indeterminate, selecting it will select all entries per the above condition.
   * If select all is checked, selecting it will clear out selection
   */
  const onSelectAll = () => {
    const selectableEntries = entries
      .flatMap((e) => {
        if (e.value?.isSection) {
          return [...(props.state.collection.getChildren?.(e.key) ?? [])];
        }
        return [e];
      })
      .filter((e) => !e.value?.disabled);

    // Current list of entry ids, and if applicable their parent
    const entryIDsInView = [
      ...selectableEntries.map((e) => e.key),
      ...(props.parentEntryID ? [props.parentEntryID] : []),
    ];

    // If every entry in view is selected, select all will remove those from the selection
    if (entryIDsInView.every((e) => state.selectionManager.selectedKeys.has(e))) {
      const filteredKeys: Key[] = [];
      state.selectionManager.selectedKeys.forEach((s) => {
        if (!entryIDsInView.includes(s)) filteredKeys.push(s);
      });
      state.selectionManager.setSelectedKeys(filteredKeys);
      return;
    }

    const currentEntryIDs = [...selectableEntries.map((e) => e.key)];
    const newSelections = props.parentEntryID
      ? [props.parentEntryID, ...currentEntryIDs]
      : currentEntryIDs;

    // Maintain current select and append rest of select all
    state.selectionManager.setSelectedKeys([
      ...state.selectionManager.selectedKeys,
      ...newSelections,
    ]);
  };

  return (
    <ul {...listBoxProps} ref={ref} className="outline-none" data-cy="entrylist">
      {props.isAllSelectable && (
        <SelectAllEntry
          isIndeterminate={
            Boolean(state.selectionManager.selectedKeys.size) && !state.selectionManager.isSelectAll
          }
          isSelected={Boolean(state.selectionManager.selectedKeys.size)}
          onSelectAll={onSelectAll}
        />
      )}
      {parentEntry && <Entry entry={parentEntry} onViewParent={props.onViewParent} state={state} />}
      {entries.map((entry) =>
        entry.value?.isSection ? (
          <Section
            key={entry.key}
            onViewChildren={props.onViewChildren}
            section={entry}
            state={state}
          />
        ) : (
          <Entry
            key={entry.key}
            entry={entry}
            onHover={props.onHoverEntry}
            onViewChildren={props.onViewChildren}
            state={state}
          />
        )
      )}
      {entries.length === 0 && (
        <div className="px-4 py-2 italic text-type-inactive type-body1">No results...</div>
      )}
    </ul>
  );
}

const SelectAllEntry = ({
  onSelectAll,
  isIndeterminate,
  isSelected,
}: {
  onSelectAll: (isChecked: boolean) => void;
  isIndeterminate: boolean;
  isSelected: boolean;
}) => (
  <li
    aria-selected={isSelected}
    className="flex gap-2 border-b border-border-muted bg-background-primary p-2 transition hover:bg-selection-hover"
    role="option"
    tabIndex={0}
  >
    <Checkbox
      aria-label="entry is selected"
      fullWidth
      isIndeterminate={isIndeterminate}
      isSelected={isSelected}
      onChange={onSelectAll}
    >
      Select All
    </Checkbox>
  </li>
);

const Section = (props: {
  onViewChildren?: ComponentProps<typeof EntryList>['onViewChildren'];
  section: Node<SelectEntry>;
  state: ListState<SelectEntry>;
}) => {
  const value = props.section.value;
  if (!value) {
    throw Error('Failed to get value from entry.');
  }

  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: value.label,
  });

  const children = props.state.collection.getChildren?.(props.section.key);
  if (!children) return null;

  return (
    <li {...itemProps}>
      <div
        className="w-full cursor-default bg-background-1 px-2 py-1 text-type-primary type-label"
        {...headingProps}
      >
        {value.label}
      </div>
      <ul {...groupProps}>
        {[...children].map((node) => (
          <Entry
            key={node.key}
            entry={node}
            onViewChildren={props.onViewChildren}
            state={props.state}
          />
        ))}
      </ul>
    </li>
  );
};

const Entry = (props: {
  entry: Node<SelectEntry>;
  onHover?: ComponentProps<typeof EntryList>['onHoverEntry'];
  onViewChildren?: ComponentProps<typeof EntryList>['onViewChildren'];
  onViewParent?: ComponentProps<typeof EntryList>['onViewParent'];
  state: ListState<SelectEntry>;
}) => {
  const ref = useRef(null);

  const { optionProps, labelProps, isSelected, isDisabled } = useOption(
    { key: props.entry.key },
    props.state,
    ref
  );

  const { hoverProps } = useHover({
    onHoverStart: () => props.onHover && props.entry.value && props.onHover(props.entry.value.id),
  });

  const value = props.entry.value;
  if (!value) {
    throw Error('Failed to get value from entry.');
  }

  const hasChildren = value.hasChildren && Boolean(props.onViewChildren);

  return (
    <li
      className={`flex w-full items-center gap-2 overflow-hidden bg-background-primary ${
        props.onViewParent ? 'border-b border-border-muted' : ''
      }`}
      {...hoverProps}
    >
      {props.onViewParent && (
        <IconButton
          aria-label="go up a level"
          icon={<Icon name="keyboard_arrow_left" />}
          onClick={props.onViewParent}
          type="secondary"
        />
      )}
      <div
        {...optionProps}
        ref={ref}
        className={[
          'flex flex-grow items-center gap-2 p-2 outline-none transition focus-visible:bg-selection-hover',
          isSelected ? 'bg-selection-selected' : 'bg-background-primary',
          isDisabled
            ? 'cursor-not-allowed text-type-inactive'
            : 'cursor-pointer text-type-primary hover:bg-selection-hover',
          hasChildren ? 'rounded-e-full' : '',
          props.onViewParent ? 'rounded-s-full' : '',
        ].join(' ')}
      >
        {props.state.selectionManager.selectionMode === 'multiple' && (
          <Checkbox
            aria-label="entry is selected"
            isDisabled={isDisabled}
            isSelected={isSelected}
          />
        )}
        {value.startAdornment}
        <div className="flex flex-1 items-center gap-2">
          <div className="flex flex-col gap-0.5">
            <div {...labelProps} className="py-0.5 type-body1">
              {value.label}
            </div>
            {value.description && <div className="py-0.5 type-label">{value.description}</div>}
          </div>
          {value.badge && <Chip text={value.badge} />}
        </div>
        {value.endAdornment}
      </div>
      {hasChildren && (
        <IconButton
          aria-label={`see children for ${value.label}`}
          data-cy="children-button"
          icon={<Icon name="keyboard_arrow_right" />}
          onClick={() => {
            props.onViewChildren?.(value.id);
          }}
          type="secondary"
        />
      )}
    </li>
  );
};

function handleKeyboardNavEvent(
  e: KeyboardEvent<Element>,
  uListEl: HTMLUListElement | null,
  focusedKey: Key | null
) {
  if (!uListEl) return;
  if (focusedKey === null) return;

  if (e.key === 'ArrowDown') {
    const nextElement = uListEl.querySelector(
      `li:has([data-key="${focusedKey}"]) ~ li:has([data-key]:not([aria-disabled])) [role=option]`
    );
    if (nextElement instanceof HTMLElement) nextElement.focus();
    else {
      const firstElement = uListEl.querySelector(
        'ul[role="listbox"] > li:has([data-key]:not([aria-disabled])) [role=option]'
      );
      if (firstElement instanceof HTMLElement) firstElement.focus();
    }
  } else if (e.key === 'ArrowUp') {
    // This doesn't handle skipping a disabled entry. TBD...
    const prevElement = uListEl.querySelector(
      `li:has(+ li > [data-key="${focusedKey}"]) [role=option]`
    );
    if (prevElement instanceof HTMLElement) prevElement.focus();
  } else if (e.key === 'ArrowRight') {
    const viewChildrenButtonElement = uListEl.querySelector(`[data-key="${focusedKey}"] + button`);
    if (viewChildrenButtonElement instanceof HTMLElement) viewChildrenButtonElement.focus();
  } else if (e.key === 'ArrowLeft') {
    const viewParentButtonElement = uListEl.querySelector(
      `button:has(+ [data-key="${focusedKey}"])`
    );
    if (viewParentButtonElement instanceof HTMLElement) viewParentButtonElement.focus();
    else {
      const optionElement = uListEl.querySelector(`[data-key="${focusedKey}"]`);
      if (optionElement instanceof HTMLElement) optionElement.focus();
    }
  }
}

import { TermKey } from '../../api/gqlEnums';
import { VIEW_OPTIONS } from '../../constants';
import { UserReportType } from '../../generated/graphql';
import { RouteKeys } from '../../routes/paths';
import { COSTREPORT_DEFAULTS } from '../CostReport/CostReport/useCostReportParams';
import { DASHBOARD_DEFAULTS } from '../dashboard/DashboardUtils';
import { ITEMSLIST_DEFAULTS } from '../ItemsList/ItemsListUtils';
import { MilestoneDetailsTabs } from '../Milestone/utils';
import { BREAKDOWNS_DEFAULTS } from '../Print/PrintBreakdowns/utils';

import { isDefaultReport } from './ReportsManagerMenu/utils';

type UserReportConfigOptions = {
  canCopyLink: boolean;
  typeDisplayName: string;
  appViewRoute?: RouteKeys;
  printViewRoute?: RouteKeys;
  exportViewRoute?: RouteKeys;
  search?: string;
};

// This maps the various reports to their properties for display in the Reports Tab
export const MapUserReportTypeToConfig: {
  [key in UserReportType]: UserReportConfigOptions | undefined;
} = {
  [UserReportType.USER_REPORT_DASHBOARD]: {
    canCopyLink: true,
    typeDisplayName: 'Dashboard',
    appViewRoute: RouteKeys.PROJECT_DASHBOARD,
    printViewRoute: RouteKeys.PRINT_PROJECT_DASHBOARD,
  },
  [UserReportType.USER_REPORT_BREAKDOWNS]: {
    canCopyLink: true,
    typeDisplayName: 'Category Trends Breakdown',
    appViewRoute: RouteKeys.PRINT_PROJECT_BREAKDOWNS,
  },
  [UserReportType.USER_REPORT_ITEMS_LIST]: {
    canCopyLink: true,
    typeDisplayName: 'Items List',
    appViewRoute: RouteKeys.PROJECT_ITEMS,
    printViewRoute: RouteKeys.PRINT_PROJECT_ITEMS_LIST,
    exportViewRoute: RouteKeys.EXPORT_PROJECT_ITEMS_LIST,
  },
  [UserReportType.USER_REPORT_ITEMS_LIST_DETAILS]: {
    canCopyLink: true,
    typeDisplayName: 'Items List & Details',
    appViewRoute: RouteKeys.PROJECT_ITEMS,
    printViewRoute: RouteKeys.PRINT_PROJECT_ITEMS_LIST_DETAILS,
  },
  [UserReportType.USER_REPORT_MSR]: {
    canCopyLink: true,
    typeDisplayName: 'Milestone Summary',
    appViewRoute: RouteKeys.PROJECT_MILESTONES_MILESTONE_MSR,
    printViewRoute: RouteKeys.PRINT_PROJECT_MSR,
    exportViewRoute: RouteKeys.EXPORT_PROJECT_MSR,
  },
  [UserReportType.USER_REPORT_VARIANCE]: {
    canCopyLink: true,
    typeDisplayName: 'Variance',
    appViewRoute: RouteKeys.PROJECT_VARIANCE,
    printViewRoute: RouteKeys.PRINT_PROJECT_VARIANCE,
    exportViewRoute: RouteKeys.EXPORT_PROJECT_VARIANCE,
  },
  [UserReportType.USER_REPORT_ACTIVE_ESTIMATE]: {
    canCopyLink: true,
    typeDisplayName: 'Milestone Estimate',
    appViewRoute: RouteKeys.PROJECT_MILESTONES_MILESTONE,
    exportViewRoute: RouteKeys.EXPORT_PROJECT_MILESTONE_ESTIMATE,
    search: `view=${MilestoneDetailsTabs.ESTIMATE}`,
  },
  [UserReportType.USER_REPORT_ACTIVE_BUDGET]: {
    canCopyLink: true,
    typeDisplayName: 'Milestone Budget',
    appViewRoute: RouteKeys.PROJECT_MILESTONES_MILESTONE,
    exportViewRoute: RouteKeys.EXPORT_PROJECT_MILESTONE_BUDGET,
    search: `view=${MilestoneDetailsTabs.TARGET}`,
  },
  [UserReportType.USER_REPORT_CONTINGENCY_DRAW]: {
    canCopyLink: true,
    typeDisplayName: 'Active Milestone Draw Report',
    appViewRoute: RouteKeys.PROJECT_CONTINGENCY_ALLOWANCE_REPORT,
    printViewRoute: RouteKeys.PRINT_PROJECT_CONTINGENCY_ALLOWANCE_REPORT,
    exportViewRoute: RouteKeys.EXPORT_PROJECT_ACTIVE_MILESTONE_CONTINGENCY,
  },
  [UserReportType.USER_REPORT_CONTINGENCY_ALL_MILESTONES]: {
    canCopyLink: true,
    typeDisplayName: 'All Milestones Draw Report',
    appViewRoute: RouteKeys.PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT,
    printViewRoute: RouteKeys.PRINT_PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT,
    exportViewRoute: RouteKeys.EXPORT_PROJECT_ALL_MILESTONES_CONTINGENCY,
  },
  [UserReportType.USER_REPORT_PROJECT_COST_BREAKDOWN]: {
    canCopyLink: true,
    typeDisplayName: 'Project Cost Breakdown',
    appViewRoute: RouteKeys.PROJECT_MILESTONES_MILESTONE_COST_BREAKDOWN_REPORT,
    printViewRoute: RouteKeys.PRINT_PROJECT_COST_BREAKDOWN,
    exportViewRoute: RouteKeys.EXPORT_PROJECT_COST_BREAKDOWN_REPORT,
  },
  [UserReportType.NONE]: undefined,
};

export type DefaultReportOptions = {
  showContingencyReport?: boolean;
  showMarkupsInTooltip?: boolean;
};

const defaultReportOptions = {
  showContingencyReport: false,
  showProjectCostBreakdownReport: false,
  showMarkupsInTooltip: false,
};

// This defines the built-in reports
export const DefaultReports = (
  activeMilestoneName = '',
  t: TermFunc = () => '',
  options: DefaultReportOptions = defaultReportOptions,
  defaultGroupBy: string[]
): UserReport[] => {
  const activeMilestoneString = activeMilestoneName ? ` (${activeMilestoneName})` : '';
  return (
    [
      {
        id: UserReportType.USER_REPORT_DASHBOARD,
        reportType: UserReportType.USER_REPORT_DASHBOARD,
        name: 'Dashboard - Active Milestone',
        description: `Dashboard view for the active milestone${activeMilestoneString} with default settings`,
        settings: JSON.stringify(DASHBOARD_DEFAULTS),
      },
      {
        id: UserReportType.USER_REPORT_BREAKDOWNS,
        reportType: UserReportType.USER_REPORT_BREAKDOWNS,
        name: 'Cost Trends By Category',
        description: 'Charts showing how costs are trending by category across milestones',
        settings: JSON.stringify(BREAKDOWNS_DEFAULTS),
      },
      options?.showContingencyReport
        ? {
            id: UserReportType.USER_REPORT_CONTINGENCY_ALL_MILESTONES,
            reportType: UserReportType.USER_REPORT_CONTINGENCY_ALL_MILESTONES,
            name: 'C&A Draw Report - All Milestones',
            description:
              'Charts showing how contingency and allowance draws are trending across milestones',
            settings: '{}',
          }
        : undefined,
      {
        id: UserReportType.USER_REPORT_ITEMS_LIST,
        reportType: UserReportType.USER_REPORT_ITEMS_LIST,
        name: 'Items List - Active Milestone',
        description: `List view of items in the active milestone${activeMilestoneString}, excluding items that are Not Applicable.`,
        settings: JSON.stringify(ITEMSLIST_DEFAULTS),
      },
      {
        id: UserReportType.USER_REPORT_ITEMS_LIST_DETAILS,
        reportType: UserReportType.USER_REPORT_ITEMS_LIST_DETAILS,
        name: 'Items List and Details',
        description: `Print a full report with a cover sheet listing items in the active milestone${activeMilestoneString} and all of their details`,
        settings: JSON.stringify(ITEMSLIST_DEFAULTS),
      },
      {
        id: UserReportType.USER_REPORT_MSR,
        reportType: UserReportType.USER_REPORT_MSR,
        name: 'Milestone Costs Summary',
        description: `Costs grouped by UniFormat and MasterFormat with columns for ${t(
          TermKey.ESTIMATE
        )}, ${t(TermKey.TARGET)}, ${t(TermKey.GAP)}, ${t(TermKey.RUNNING_TOTAL)}, and ${t(
          TermKey.DELTA
        )}`,
        settings: JSON.stringify({
          ...COSTREPORT_DEFAULTS,
          groupBy: defaultGroupBy,
          viewMode: VIEW_OPTIONS.CHART_AND_LIST,
        }),
      },
      {
        id: `${UserReportType.USER_REPORT_MSR}_ITEMS`,
        reportType: UserReportType.USER_REPORT_MSR,
        name: 'Item Costs Summary',
        description:
          'Items in the active milestone with columns for Pending, Accepted, Incorporated, and Rejected',
        settings: JSON.stringify({
          ...COSTREPORT_DEFAULTS,
          groupBy: defaultGroupBy,
          viewMode: VIEW_OPTIONS.LIST,
        }),
      },
      {
        id: UserReportType.USER_REPORT_ACTIVE_ESTIMATE,
        reportType: UserReportType.USER_REPORT_ACTIVE_ESTIMATE,
        name: 'Active Milestone Estimate',
        description: `Download the active milestone${activeMilestoneString} estimate as a .xlsx`,
        settings: '{}',
      },
      {
        id: UserReportType.USER_REPORT_ACTIVE_BUDGET,
        reportType: UserReportType.USER_REPORT_ACTIVE_BUDGET,
        name: 'Active Milestone Budget',
        description: `Download the active milestone${activeMilestoneString} budget as a .xlsx`,
        settings: '{}',
      },
      {
        id: UserReportType.USER_REPORT_PROJECT_COST_BREAKDOWN,
        reportType: UserReportType.USER_REPORT_PROJECT_COST_BREAKDOWN,
        name: 'Project Cost Breakdown',
        description: `Chart that visualizes the ${
          options?.showMarkupsInTooltip
            ? `${t(TermKey.DIRECT_COST)}, ${t(TermKey.MARKUP)}, Contingencies, Allowances,`
            : t(TermKey.COST_OF_CONSTRUCTION)
        }
        } and Owner Costs that exist in a Milestone ${t(TermKey.ESTIMATE)} or ${t(TermKey.TARGET)}`,
      },
    ] as UserReport[]
  ).filter((r) => r);
};

export const reportForAnalytics = (r: UserReport) => {
  const { id, name, description, reportType } = r as UserReport;
  const isDefault = isDefaultReport(id);
  return { id, name, description, reportType, isDefault };
};
